import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Slider } from "react-burgers";
import cx from "classnames";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import {
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

const AppMobileMenu = props => {

  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  const toggleMobileSmall = () => {
    let { enableMobileMenuSmall, setEnableMobileMenuSmall } = props;
    setEnableMobileMenuSmall(!enableMobileMenuSmall);
  };

  const [state, setState] = useState({
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false,
  })

  return (
    <Fragment>
      <div className="app-header__mobile-menu">
        <div onClick={toggleMobileSidebar}>
          <Slider width={26} lineHeight={2} lineSpacing={5} color="#6c757d" 
              active={state.active} onClick={() => setState({ active: !state.active })}/>
        </div>
      </div>
      <div className="app-header__menu">
        <span onClick={toggleMobileSmall}>
          <Button size="sm" className={cx("btn-icon btn-icon-only", {
                active: state.activeSecondaryMenuMobile,
              })}
              color="primary"
              onClick={() =>
                setState({
                  activeSecondaryMenuMobile: !state
                    .activeSecondaryMenuMobile,
                })
              }>
            <div className="btn-icon-wrapper">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          </Button>
        </span>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);
