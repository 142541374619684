import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import { useAtom } from "jotai"
import ResizeDetector from "react-resize-detector";
import { withCookies } from 'react-cookie';
import { auth } from './_services/user'

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import AppMain from "./Layout/AppMain";
import { userAtom } from "./_atoms/user";
import Loader from "react-loaders";

const Login = React.lazy(() => import("./Pages/UserPages/Login/index"));
const Register = React.lazy(() => import("./Pages/UserPages/Register/index"));

const App = props => {

  const [user, setUser] = useAtom(userAtom)
  const { token } = props.cookies.cookies

  const { loggedIn } = user

  useEffect(() => {
    if (token && token !== '') {
      auth(token)
      .then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setUser({ ...res.data.data, loggedIn: true, token: token });
        } else {
          document.cookie = "token="
        }
      });
    }
  }, [token])

  let {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  return (
    <HashRouter>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => { return loggedIn ? <Redirect to='/' /> : <Login {...props} /> }} />
          <Route exact path="/register" name="Register Page" render={props => { return loggedIn ? <Redirect to='/' /> : <Register {...props} /> }} />
          <Route path="/" name="Anasayfa" render={props2 => { return loggedIn 
          ? <ResizeDetector {...props} {...props2} handleWidth render={({ width }) => (
              <Fragment>
                <div
                  className={cx(
                    "app-container app-theme-" + colorScheme,
                    { "fixed-header": enableFixedHeader },
                    { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                    { "fixed-footer": enableFixedFooter },
                    { "closed-sidebar": enableClosedSidebar || width < 1250 },
                    {
                      "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                    },
                    { "sidebar-mobile-open": enableMobileMenu },
                    { "body-tabs-shadow-btn": enablePageTabsAlt }
                  )}>
                  <AppMain />
                </div>
              </Fragment>
            )}
          /> 
          : <Redirect to='/login' /> }} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withCookies(withRouter(connect(mapStateToProp)(App)));
