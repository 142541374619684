const home = [
  {
    icon: "pe-7s-home",
    label: 'Home',
    to: '#/dashboard',
  },
  {
    icon: "pe-7s-plugin",
    label: 'Packets',
    to: '#/packets',
  },
  /*{
    icon: "pe-7s-plugin",
    label: 'Countries',
    to: '#/countries',
  },*/
]
const company = [
  {
    icon: "lnr-list",
    label: 'Categories',
    content: [
      {
        label: 'Categories',
        to: '#/categories'
      },
    ]
  },
  {
    icon: "lnr-pie-chart",
    label: 'Products',
    content: [
      {
        label: 'Awaiting Approval',
        to: '#/awaiting-approval'
      }
    ]
  },
  {
    icon: "lnr-apartment",
    label: 'Companies',
    content: [
      {
        label: 'Company Applications',
        to: '#/company-applications'
      },
      {
        label: 'Company Types',
        to: '#/company-types'
      },
      {
        label: 'Companies',
        to: '#/companies'
      },
    ]
  },
  {
    icon: "pe-7s-id",
    label: 'Members',
    to: '#/members',
  },
]
export default { home, company, };


