import React, { Fragment, useState } from "react";
import {
  Popover,
  Nav,
  NavLink,
  Col,
  Row,
  NavItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
const MegaMenu = () => {

  const [state, setState] = useState({
    dropdownOpen: false,
    popoverOpen: false,
  });

  const toggle = () => {
    setState({
      dropdownOpen: !state.dropdownOpen,
      popoverOpen: !state.popoverOpen,
    });
  }

  return (
    <Fragment>
      <Nav className="header-megamenu">
        <NavItem>
          <NavLink href="#" onClick={toggle} id="PopoverMegaMenu">           
                Help
            <FontAwesomeIcon className="ms-2 opacity-5" icon={faAngleDown} />
          </NavLink>
        </NavItem>
        <Popover className="rm-max-width" placement="bottom-start" fade={false} trigger="legacy"
            isOpen={state.popoverOpen} target="PopoverMegaMenu" toggle={toggle}>
          <div className="dropdown-mega-menu">
            <div className="grid-menu grid-menu-3col">
              <Row className="g-0">
                <Col xl="4" sm="6">
                  <Nav vertical>
                    <NavItem className="nav-item-header">Overview</NavItem>
                    <NavItem>
                      <NavLink href="#">
                        <i className="nav-link-icon lnr-inbox"> </i>
                        <span>Contacts</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col xl="4" sm="6">
                  <Nav vertical>
                    <NavItem className="nav-item-header">Favourites</NavItem>
                    <NavItem>
                      <NavLink href="#">Reports Conversions</NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col xl="4" sm="6">
                  <Nav vertical>
                    <NavItem className="nav-item-header">
                        Sales &amp; Marketing
                    </NavItem>
                    <NavItem>
                      <NavLink href="#">Queues</NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </div>
          </div>
        </Popover>
      </Nav>
    </Fragment>
  );
}

export default MegaMenu;
