import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import _nav from "./_myNav";

const Nav = props => {

  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Home</h5>
      <MetisMenu content={_nav.home} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
      <h5 className="app-sidebar__heading">Utils</h5>
      <MetisMenu content={_nav.company} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
