import React from "react"


const Home = React.lazy(() => import('./Pages/Home/index'));
const Applications = React.lazy(() => import('./Pages/Company/Applications'))
const CompanyTypes = React.lazy(() => import('./Pages/Company/CompanyTypes'))
const CompanyType = React.lazy(() => import('./Pages/Company/CompanyType'))
const Companies = React.lazy(() => import('./Pages/Company/Companies'))
const Company = React.lazy(() => import('./Pages/Company/Company'))
const Uyeler = React.lazy(() => import('./Pages/Company/Uyeler')) 
const Categories = React.lazy(() => import('./Pages/Categories/Categories')) 
const Category = React.lazy(() => import('./Pages/Categories/Category'))
const ReviewList = React.lazy(() => import('./Pages/Products/ReviewList')) 
const InReview = React.lazy(() => import('./Pages/Products/InReview'))
const Subscriptions = React.lazy(() => import('./Pages/Subscriptions/Subscriptions')) 
const Subscription = React.lazy(() => import('./Pages/Subscriptions/Subscription')) 

const Countries = React.lazy(() => import('./Pages/Location/Countries')) 
const Country = React.lazy(() => import('./Pages/Location/Country')) 
const Cities = React.lazy(() => import('./Pages/Location/Cities'))
const City = React.lazy(() => import('./Pages/Location/City')) 
const Districts = React.lazy(() => import('./Pages/Location/Districts')) 
const District = React.lazy(() => import('./Pages/Location/District')) 

const Customers = React.lazy(() => import('./Pages/Customers/Customers')) 

const routes = [
    { path: '/', exact: true, name: 'trcontact',},
    { path: '/dashboard', name: 'Home', component: Home},
    { path: '/categories', name: 'Categories', component: Categories},
    { path: '/category-detail', name: 'Category Detail', component: Category},
    { path: '/company-applications', name: 'Company Applications', component: Applications},
    { path: '/company-types', name: 'Company Types', component: CompanyTypes},
    { path: '/company-type-detail', name: 'Company Type', component: CompanyType},
    { path: '/companies', name: 'Companies', component: Companies},
    { path: '/company-detail', name: 'Company', component: Company},
    { path: '/awaiting-approval', name : 'Awaiting Approval', component : ReviewList },
    { path: '/awaiting-approval-detailing', name : 'Products Pending Approval', component : InReview },
    { path: '/packets', name : 'Packets', component : Subscriptions },
    { path: '/packet-detail', name : 'Packet Detail', component : Subscription },
    { path: '/countries', name : 'Countries', component : Countries },
    { path: '/country-detail', name : 'Country Detail', component : Country },
    { path: '/cities', name : 'Cities', component : Cities },
    { path: '/city-detail', name : 'City Detail', component : City },
    { path: '/districts', name : 'Districts', component : Districts },
    { path: '/district-detail', name : 'District Detail', component : District },

    { path: '/members', name : 'Members', component : Customers },

]

export default routes;